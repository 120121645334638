import React from "react";
import Layout from "../components/layout";
import { Container } from "reactstrap";

import withUIKit from "../withUIKit";
import CustomNavbar from "../components/Custom/Navbar";
import AboutHeader from "../components/Custom/Headers/AboutHeader";
import { LocalizedButton } from "../components/language/LocalizedButton";

const About = props => {
  return (
    <Layout {...props} >
      <CustomNavbar {...props} />
      <AboutHeader />
      <div className="wrapper">
          
        <div className="section landing-section text-center">
            <Container>
                <h2 className="mb-3">Wer ist citylove?</h2>
                <p>citylove.community ist ein Projekt von</p>

                <div
                className="ml-auto mr-auto mt-4"
                style={{
                  width: 100,
                  height: 100,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundImage:
                    "url(" +
                    require("../images/logos/networkventures_logo_full.svg") +
                    ")"
                }}
              />
              <div className="mt-4">
                <h6>
                    Network Ventures <span className="text-lowercase">e</span>Gen mbH
                </h6> 
                <p>
                <a href="mailto:hello@ntwk.ventures">hello@ntwk.ventures</a><br/>
                +43 677 614 480 10<br/>
                Leopoldstraße 24<br/>
                6020 Innsbruck
                </p>
              </div>
            </Container>
        </div>

        <hr />

        <div className="section landing-section">
            <Container>
                <h4>Impressum</h4>
                <p>
                    Alle Formulierungen sind stets geschlechtsneutral zu lesen.
                </p>
                <p>
                    citylove.community verbindet Unternehmen (im Folgenden: „Unternehmen“) und Konsumenten (im Folgenden: „Kunden“) dieser Leistungen und sonstige dritte Personen (im Folgenden „Dritte“).
                </p>
                <h4>
                    Vermittlertätigkeit von citylove.community
                </h4>
                <h5 className="mt-3">
                    Vermittlertätigkeit und Haftung
                </h5>
                <p>
                    citylove.community tritt gegenüber Dritten, Lokalen, Shops, Unternehmern und deren Kunden als Vermittler auf. citylove.community erbringt selbst keine Leistungen sondern stellt nur die Gutscheine zur Verfügung.
                </p>
                <p>
                    citylove.community stellt Gutscheine von Dritten auf citylove.community online und bietet diese zum Kauf an. Der Kaufpreis wird abzüglich einer Bearbeitungsgebühr von 10% an den Dritten, Lokal, Shop oder  Unternehmern weitergeleitet.
                </p>
                <p>
                    citylove.community tritt gegenüber Dritten, Kunden und den Lokalen, Shops, Unternehmern als Vermittler auf und übernimmt keine Haftung insbesondere hinsichtlich der Qualität, der Zulässigkeit und/oder der Services des Unternehmens. Auch übernimmt citylove.community keine Haftung für das Zustandekommen des Rechtsgeschäfts zwischen dem Dritten, Kunden und dem jeweiligen Unternehmen.​​
                </p>
                <p>
                    Die Haftung von citylove.community für Handlungen oder Unterlassungen Dritter, Kunden und der Unternehmen wird ausgeschlossen. Dieser Haftungsausschluss von citylove.community umfasst auch mittelbare Schäden, Folgeschäden und entgangenen Gewinn von Dritten, Kunden und Unternehmen.
                </p>
                <h5 className="mt-3">
                    Datenschutz bei Vermittlertätigkeit
                </h5>
                <p>
                    citylove.community wird beim Umgang mit personenbezogenen Daten die anwendbaren datenschutzrechtlichen Bestimmungen idgF. sowie die Bestimmungen des Telekommunikationsgesetzes beachten und die für den Datenschutz in deren Verantwortungsbereich erforderlichen technischen und organisatorischen Maßnahmen treffen. citylove.community verpflichtet sich, die Einhaltung der datenschutzrechtlichen Bestimmungen, insbesondere § 6 Datenschutzgesetz durch sich selbst sowie durch seine Mitarbeiter sicherzustellen.​
                </p>
                <p>
                    Sowohl Dritte, Kunden und das Unternehmen erklärt das ausdrückliche Einverständnis zur Erhebung, Nutzung, Weitergabe, Speicherung und zum Schutz der jeweiligen personen- oder unternehmensbezogenen Daten nach Maßgabe des Datenschutzgesetzes idgF und soweit dies zur Vertragserfüllung notwendig ist. Insbesondere erklären Dritte und Kunden in Bezug auf ihre Kontaktdaten (Name und Emailadresse) und Höhe des Gutscheins und das Unternehmen erklärt in Bezug seine Kontaktdaten (Name, Firmenname, Lokalname, Email, Telefonnummer, Standort, URL, hochgeladene Bilddateien und übermittelte Nachrichten etc.) ihr ausdrückliches Einverständnis, dass diese Daten von citylove.community zum Betreiben des Dienstes verwendet werden.
                </p>
                <h5 className="mt-3">
                    Verantwortlicher für Datenverarbeitungen
                </h5>
                <p>
                    Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO):<br/>
                    citylove.community ist Projekt von<br/>
                    <strong>Network Ventures eGen mbH</strong><br/>
                    Leopoldstraße 24, 6020 Innsbruck<br/>
                    <a href="mailto:hello@ntwk.ventures">hello@ntwk.ventures</a>
                </p>
                <h5 className="mt-3">
                    Verarbeitung personenbezogener Daten von Unterstützern, Interessenten und Lokalbetreibern
                </h5>
                <p>
                    Wir verarbeiten jene Daten, die uns Interessenten für den Gutscheinkauf sowie Lokalbetreiber durch eigene Angaben, im Rahmen einer Anfrage per Email oder durch unsere Online Formulare zur Vermittlung eines Gutscheinkaufs auf der Website zur Verfügung stellen. Dazu gehören bei Interessenten Vor- und Nachname sowie die übermittelte E-Mail-Adresse. Von Lokalbetreibern verarbeiten Vor- und Zuname, Emailadresse, Anschrift des Unternehmens, Telefonnummer, IBAN und BIC.
                </p>
                <p>
                    Die Tätigkeit ist auf das Vermitteln von Kontakten zwischen Interessenten sowie Unternehmen beschränkt. Weitere Datenverarbeitungen finden nicht statt.
                </p>
                <p>
                    Die Daten werden von uns gelöscht, sobald eine Speicherung nicht mehr erforderlich ist.
                </p>
                <p>
                    Rechtsgrundlage: Einwilligung gem. Art. 6 Abs. 1 lit a DSGVO, um Ihre Anfragen zur vollsten Zufriedenheit abwickeln zu können.
                </p>
                <h5 className="mt-3">
                    Erhebung personenbezogener Daten bei Besuch unserer Website
                </h5>
                <p className="mt-3">
                    <strong>Informatorische Nutzung der Website</strong>
                </p>
                <p>
                Bei der bloß informatorischen Nutzung der Website, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir höchstens jene Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:
                </p>
                <ul>
                    <li>
                    IP-Adresse. Datum und Uhrzeit der Anfrage
                    </li>
                    <li>
                    Zeitzonendifferenz zur Greenwich Mean Time (GMT), Inhalt der Anforderung (konkrete Seite)
                    </li>
                    <li>
                    Zugriffsstatus/HTTP-Statuscode, Website, von der die Anforderung kommt
                    </li>
                    <li>
                    Browser, Betriebssystem und dessen Oberfläche
                    </li>
                    <li>
                    Sprache und Version der Browsersoftware.
                    </li>
                </ul>
                <p>
                Rechtsgrundlage: Art. 6 Abs. 1 lit. f DSGVO
                </p>
                <p className="mt-3">
                    <strong>Cookies</strong>
                </p>
                <p>
                Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die das Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen.
                </p>
                <p>
                Durch das Cookie können Sie beim Besuch der Webseite wiedererkannt werden, ohne dass Daten, die Sie bereits zuvor eingegeben haben, nochmals eingegeben werden müssen.
                </p>
                <p>
                Die in den Cookies enthaltenen Informationen werden z.B. verwendet, um festzustellen, ob Sie eingeloggt sind, oder welche Daten Sie bereits eingegeben haben, oder um Sie als Nutzer wiederzuerkennen, wenn eine Verbindung zwischen unserem Web-Server und Ihrem Browser hergestellt wird. Bei den meisten Web-Browsern werden Cookies automatisch akzeptiert.
                </p>
                <p>
                Mit der Nutzung unserer Webseiten sind Sie, soweit Cookies nach Ihren Browser-Einstellungen akzeptiert werden, mit dem Einsatz dieser Cookies einverstanden.
                </p>
                <p className="mt-3">
                    <strong>Transiente Cookies</strong>
                </p>
                <p>
                Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
                </p>
                <p className="mt-3">
                    <strong>Persistente Cookies</strong>
                </p>
                <p>
                Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
                </p>
                <p className="mt-3">
                    <strong>Drittanbieter-Cookies</strong>
                </p>
                <p>
                Diese stammen von anderen Anbietern als dem Betreiber der Webseite. Sie können beispielsweise zum Sammeln von Informationen für Werbung, benutzerdefinierte Inhalte und Web-Statistiken verwendet werden.
                </p>
                <p className="mt-3">
                    <strong>Browser</strong>
                </p>
                <p>
                Die meisten Browser sind standardmäßig so eingestellt, dass sie alle Cookies akzeptieren. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein. Sie können auf Ihrem PC gespeicherte Cookies jederzeit selbst durch Löschen der temporären Internetdateien entfernen. Rechtsgrundlage: Art. 6 Abs. 1 lit. f DSGVO (bei technischen Cookies), Art. 6 Abs 1 lit. a (bei allen anderen Cookies) 
                </p>
                <h5 className="mt-3">
                Ihre Rechte
                </h5>
                <p>
                Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
                </p>
                <ul>
                    <li>
                    Recht auf Auskunft, Berichtigung und Löschung
                    </li>
                    <li>
                    Recht auf Einschränkung der Verarbeitung
                    </li>
                    <li>
                    Recht auf Widerspruch gegen die Verarbeitung
                    </li>
                    <li>
                    Recht auf Datenübertragbarkeit
                    </li>
                </ul>
                <p>
                Sollten Sie der Auffassung sein, dass wir bei der Verarbeitung Ihrer Daten gegen österreichisches oder europäisches Datenschutzrecht verstoßen und dadurch Ihre Rechte verletzt haben, ersuchen wir Sie mit uns in Kontakt zu treten, um allfällige Fragen aufklären zu können.
                </p>
                <p>
                Sie haben zudem das Recht, sich bei der Aufsichtsbehörde, das ist die österreichische Datenschutzbehörde, zu beschweren: Österreichische Datenschutzbehörde, Barichgasse 40 – 42, 1030 Wien, Telefon: +43 (1) 52 152-0 E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a>
                </p>
                <p className="mt-3">
                    <strong>Änderungen dieser Datenschutzerklärung</strong>
                </p>
                <p>
                Wir behalten uns vor, von Zeit zu Zeit Anpassungen an unserer Datenschutzerklärung vorzunehmen. Alle Änderungen der Datenschutzerklärung werden von uns auf dieser Seite veröffentlicht. Bitte beachten Sie diesbezüglich die jeweils aktuelle Version unserer Datenschutzerklärung.
                </p>
                <h4>
                    Websitenutzung
                </h4>
                <p className="mt-3">
                    <strong>Haftung, Urheberrecht</strong>
                </p>
                <p>
                Die Inhalte dieser Website wurden von citylove.community mit größtmöglicher Sorgfalt und nach bestem Gewissen erstellt. citylove.community übernimmt für die Informationen auf dieser Website soweit gesetzlich zulässig keine Haftung und keine Gewähr für die Aktualität, Vollständigkeit und Richtigkeit der bereitgestellten Informationen. citylove.community ist berechtigt, Teile der Seiten bzw. Inhalte oder die gesamte Website ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                </p>
                <p>
                ​Bei direkten oder indirekten Verweisen auf fremde Websites, die außerhalb des Verantwortungsbereiches von citylove.community liegen, erklärt citylove.community, dass zum Zeitpunkt der erstmaligen Verknüpfung der Links keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Die jeweiligen Betreiber der Seiten haften daher für die jeweiligen Seiten. Auf die aktuelle und zukünftige Gestaltung und auf die Inhalte oder die Urheberschaft der verknüpften Seiten hat citylove.community keinerlei Einfluss. Das Setzen von externen Links bedeutet nicht, dass sich citylove.community den auf der zu verlinkenden Seite enthaltenen Inhalt zu Eigen macht. Eine ständige Kontrolle des Inhalts der zu verlinkenden Seite ist für citylove.community ohne konkrete Hinweise auf Rechtsverletzungen nicht zumutbar. Bei Kenntnis von Rechtsverletzungen wird citylove.community jedoch die entsprechenden Verlinkungen unverzüglich löschen.
                </p>
                <p>
                ​Die Inhalte und Werke auf dieser Website sind urheberrechtlich geschützt. Jede vom österreichischen Urheberrecht nicht zugelassene Verwertung (wie insbesondere die Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung oder Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen) ist daher nur nach vorheriger schriftlicher Zustimmung des jeweiligen Urhebers zulässig. Die unerlaubte Verwertung, Vervielfältigung, Weitergabe oder sonstige Verwendung von Inhalten oder Seiten oder Teilen von diesen ist nicht erlaubt und strafbar. Die Darstellung dieser Website in fremden Frames ist nur nach vorheriger Zustimmung von citylove.community zulässig.
                </p>
                <h4>
                    ​Marketing
                </h4>
                <p>
                Damit citylove.community ihre Dienste betreiben und bereitstellen kann, gewähren Dritte, Kunden und das Unternehmen citylove.community eine weltweite, nicht-exklusive, gebührenfreie, unterlizenzierbare und übertragbare Lizenz zur Nutzung, Reproduktion, Verbreitung, Erstellung abgeleiteter Werke, Darstellung und Aufführung der Informationen (einschließlich der Inhalte), die Dritte, Kunden und das Unternehmen auf bzw. über Dienste/n von citylove.community hochladen, übermitteln, speichern, senden oder empfangen. Die von Dritten, Kunden und dem jeweiligen Lokal im Rahmen dieser Lizenz gewährten Rechte beschränken sich nicht nur auf den Zweck, die Dienste von citylove.community zu betreiben und bereitzustellen (beispielsweise citylove.community zu gestatten, Bilder von Dritten und Nutzern anzuzeigen, deren Nachrichten zu übermitteln, deren nicht zugestellten Nachrichten für bis zu 30 Tage auf den Servern von citylove.community zu speichern), sondern auch die Verwendung derselben für Marketingzwecke von citylove.community.
                </p>
                <p style={{"font-size":"60%"}}>Build {process.env.GATSBY_BUILD_TIME}</p>
            </Container>
        </div>

        <hr />
        
        <div className="section text-center landing-section">
         {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/"
            >
              Gutscheine finden
            </LocalizedButton> */}
            {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/how-to"
            >
              So funktioniert's
            </LocalizedButton> */}
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/joinin"
            >
              Partner werden
            </LocalizedButton>
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/disclaimer"
            >
              AGB
            </LocalizedButton>
        </div>
      </div>
    </Layout>
  );
};

export default withUIKit(About);
