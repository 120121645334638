import React from "react";
import { Container } from "reactstrap";

const AboutHeader = () => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header page-header-fit"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("../../../images/bgs/citylove_map_grey.jpg") + ")"
        }}
      >
        <div className="content-center">
          <Container>
            <div
              className="ml-auto mr-auto 0mb-5"
              style={{
                width: 150,
                height: 150,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" +
                  require("../../../images/logos/citylove_logo_full.svg") +
                  ")"
              }}
            ></div>
            <h2 className="title">Über citylove</h2>
            <h5 className="">
                Covid19 hat unser aller Leben verändert und bringt viele Unsicherheiten mit sich. Im Handel und in der Gastronomie bleiben die Umsätze aus. Wir bleiben zu Hause und sehnen uns nach Normalität.<br/>
                Mit citylove kannst du dir und deinen Liebsten eine Vorfreude schenken, während du gleichzeitig regionale Betriebe direkt unterstützt. Denn gerade in Krisenzeiten ist Zusammenhalt wichtiger denn je.
            </h5>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AboutHeader;
